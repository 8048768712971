import React from "react"
import Layout from "../components/layout"

const BookingTerms = () => {
  return (
    <Layout>
      <div className="antialised">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto">
              <svg
                className="absolute top-12 left-full transform translate-x-12"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto mb-6">
              <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Läs detta innan du bokar
              </p>
              <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Bokningsvillkor
              </h1>
              <p className="text-xl text-gray-500 leading-8">
                Ska du göra en större förändring med bl.a avfärgning,
                helblekning eller Crazy Colors så bokar du det via telefon
                0651-300555.
              </p>
            </div>
            <div className="prose prose-lg text-gray-500 mx-auto">
              <p>
                Tänk på att om vi gör en annan behandling än den bokade så kan
                priset ändras. Det kan även bli ett merfärgstillägg om det går
                åt mer färg än normalt.
              </p>
              <p>
                Avbokning sker minst <strong>24 timmar</strong> före bokad tid.
                Vid senare avbokning debiterar vi med 75% av beloppet för bokad
                behandling.
              </p>

              {/* <p>
                Vi vill därför be dig som varit utomlands, oavsett vart, att
                avvakta minst två veckor innan du kommer till oss på behandling,
                så det inte finns någon risk att du bär på covid-19 som inte
                brutit ut. Vi vill också att du kontaktar oss för att omboka din
                tid vid symptom på sjukdom. Det gör du då via telefon eller vår
                onlinebokning. Vi hjälper dig såklart på bästa sätt att hitta en
                ny tid!
              </p>
              <p>
                Vi på salongen har utökat våra städrutiner och tvättar
                kontinuerligt våra händer med tvål och vatten (självklart) och
                har även tillgång till handsprit för alla som vistas i lokalen.{" "}
              </p> */}

              <span>
                {" "}
                Sofie, Sara, Linnea & Lina <br /> Studio Ess
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default BookingTerms
